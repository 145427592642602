<template>
  <div class="about">
    <h1>Gabriele Schlesselmann</h1>

    <br/>

    <p>Knesebeckstraße 20</p>
    <p>10623 Berlin</p>
    <p>Tel: 0173 - 5103677</p>

    <br/>

    <p>Email: schlesselmann.g@web.de</p>
  </div>
</template>
<script setup>
</script>